export const appConfig={
    signUpUrl:'https://rndq17dw9h.execute-api.ca-central-1.amazonaws.com/',
    dummyUrl:'https://jsonplaceholder.typicode.com/posts',
    
}

export const DAYS={
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }
  
export const SignUpState = {
    ERROR:0,
    INCOMPLETE:1,
    COMPLETE:2
  }