import React from "react"
import Head from "../components/head"

import Layout from "../components/layout"
import SignUpForm from "../components/index/signup-form"
import Testimonial from "../components/index/testimonial"

import Image from "../utils/use-gatsby-local-image.js"

import No_Phone from "../../static/images/no_phone.svg"
import Stat from "../../static/images/stat.svg"
import Rect from "../../static/images/checkbox.svg"
import FaithTechDark from "../../static/images/faithtech_logo_dark.svg"
import faq_list from "../../static/data/faq_list.json"

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Head title={"Home"} />
        <SignUpForm
          title="Could you go one day a week without digital technology?"
          disclaimer="We'll use this email to remind you about your upcoming Digital Sabbath on whatever day you select."
        />
        <Layout activePage="Home">
          <Testimonial heart_hex="#19852F">
            "[The Digital Sabbath] felt like I was walking into fresh water...”
          </Testimonial>
          <div className="flex-col flex md:flex-row">
            <div className="w-full mb-4 space-y-3 mr-8 md:mb-0 md:w-5/12">
              <h2 className="normalize">One day a week</h2>
              <div className="flex items-center pb-1">
                <h2 className="mr-2">No Devices</h2>
                <img
                  src={No_Phone}
                  alt="Mobile phone with a line crossing it out"
                  className="w-10"
                />
              </div>
              <p>
                The Digital Sabbath is a 3 month challenge where, for one day
                a week, you avoid digital technology. The goal of the challenge
                is to lower screen time so you can spend that time reflecting on
                God's faithfulness or his Word, spend more time with family and
                friends, try something different, or just rest. In a society that
                runs 24/7, the Digital Sabbath is a unique challenge that can be
                liberating, and as one participant put it, "like walking into fresh
                water".
              </p>
              <p>
                <span className="text-primary font-weight-bold">Here's how it works:</span> We send you a
                tailored email the night before your digital sabbath day with a reminder,
                a reflective verse, and some resources. These emails come once a week,
                the day before your Digital Sabbath begins.
              </p>
            </div>
            <div className="w-full md:w-7/12">
              <img
                src={Stat}
                alt="Stat showing the average time Canadians spend on their phone per day"
                className="my-4"
              />
            </div>
          </div>
          <Testimonial heart_hex="#E6C1B4">
            "It’s a very freeing experience, that’s why I wish my other friends
            would try it."
          </Testimonial>
          <div className="flex-col flex md:flex-row mb-4 rounded-2xl bg-primary-15 shadow-md">
            <div className="w-full mb-4 mx-8 space-y-3 p-5 md:mb-0 ">
              <h2 className="text-black font-bold my-3">Here's how it works</h2>
              <ul className="text-2xl">
                <li className="my-4">
                  <span><img
                    src={Rect}
                    alt="An empty check box"
                    className="w-10 inline-block mr-3"
                  /></span> Sign up for the Digital Sabbath</li>
                <li className="my-4"><span><img
                  src={Rect}
                  alt="An empty check box"
                  className="w-10 inline-block mr-3"
                /></span> Get the reminder email in your inbox
                </li>
                <li className="my-4">
                  <span><img
                    src={Rect}
                    alt="An empty check box"
                    className="w-10 inline-block mr-3" />
                  </span> Begin your digital sabbath (no screens!)
                </li>
                <li className="my-4"> <span><img
                  src={Rect}
                  alt="An empty check box"
                  className="w-10 inline-block mr-3"
                /></span> Tag #digitalsabbath on social media after your sabbath day</li>
                <li className="my-4"> <span><img
                  src={Rect}
                  alt="An empty check box"
                  className="w-10 inline-block mr-3"
                /></span> Repeat next week!</li>
              </ul>
            </div>
          </div>
          <div className="flex-col flex md:flex-row">
            <div className="w-full my-3 mb-4 mr-8 space-y-3 md:w-1/3 md:mb-0">
              <h2>
                <span>Sabbath: </span>
                <span className="normalize">rest</span>
              </h2>
              <p>
                We’re using the term sabbath because it’s a beautiful term that
                means abstinence from work - in our digital age, this has become
                increasingly neglected and its power misunderstood.
              </p>
              <p>
                Sabbath is a biblical term, appearing throughout the Bible. Even
                God rested, and he tells us to rest... he tells us to rest by
                going to him.
              </p>
            </div>
            <div className="w-full md:w-7/12 flex items-center ml-auto">
              <div className="w-1/2 md:w-2/5 mr-6">
                <Image
                  fileName="rest.png"
                  alt="Illustration of a person reading a book"
                />
              </div>
              <p className="w-1/2 md:w-3/5 text-lg text-primary">
                "Come to me, all you who are weary and burdened, and I will give
                you rest."
                <br />
                Matthew 11:28
              </p>
            </div>
          </div>
          <Testimonial heart_hex="#22A9B9">
            "The Digital Sabbath is fantastic. I would recommend it to anyone
            looking for rest"
          </Testimonial>
          <h2 className="mb-6 text-pink-500">FAQ: frequently asked questions</h2>
          <div className="grid-cols-1 grid gap-6 md:gap-4 md:grid-cols-3 mb-16">
            {faq_list.map(({ question, answer }, index) => (
              <div key={index}>
                <h3 className="font-bold text-pink-500 mb-2">{question}</h3>
                <p>{answer}</p>
              </div>
            ))}
          </div>
          <h2 className="mb-6 text-orange">Contact: <span class="normalize">have questions?</span></h2>
          <div className="grid-cols-1 grid gap-6 mb-16">
            <p>Digital Sabbath is maintained by FaithTech, a global tech community for Christ. To contact
              Digital Sabbath, submit your questions or comments to <a className="underline" href="https://faithtech.com/contact-us" target="_blank" rel="noopener noreferrer">faithtech.com/contact-us</a>, or use the link
              below. This link will take you to a new page.</p>
            <p className="text-2xl  text-gray-500">
              <a className="bold border-b-2" href="https://faithtech.com/contact-us" target="_blank" rel="noopener noreferrer">
                Contact Us: FaithTech
              </a></p>
            <a
              href="https://faithtech.com/"
              target="_blank"
              rel="noopener noreferrer"
              >
              <img src={FaithTechDark} alt="FaithTech logo" className="w-40 align-middle" />
            </a>
          </div>
        </Layout>
      </>
    )
  }
}
