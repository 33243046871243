import React from "react"
import Image from "../../utils/use-gatsby-local-image.js"
import { validateEmail } from "../../utils/validators";
import SignUpDone from "../../../static/images/signup_done.svg";
import { appConfig, DAYS, SignUpState } from "../../utils/environment";

import FaithTech from "../../../static/images/faithtech_logo.svg"


export default class SignUphtmlForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSignUp = this.handleSignUp.bind(this)
    this.state = {
      email: "",
      reminderDay: 0,
      signUpComplete: SignUpState.INCOMPLETE,
      validEmail: true,
      processingRequest: false
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState({ signUpComplete: SignUpState.INCOMPLETE, validEmail: true })
    if (name === "emailAddress") {
      //validate first
      if (!validateEmail(value)) {
        this.setState({ validEmail: false });
      }
      this.setState({ email: value });
    }
    if (name === "reminderDay") {
      this.setState({ reminderDay: value });
    }
  }

  handleSignUp() {
    if (this.state.reminderDay === -1) {
      this.setState({ processingRequest: false, signUpComplete: SignUpState.ERROR });
      return;
    }
    if (!this.state.email) {
      this.setState({ processingRequest: false, validEmail: false, signUpComplete: SignUpState.ERROR });
      return;
    }
    if (this.state.email === '') {
      this.setState({ processingRequest: false, signUpComplete: SignUpState.ERROR });
      return;
    }
    // set remind email day to a day before
    const remindOn = (((this.state.reminderDay - 1) % 7) + 7) % 7;

    //do the sign up request
    this.setState({ processingRequest: true });
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ email: this.state.email, remindOn: remindOn })
    };
    fetch(appConfig.signUpUrl, requestOptions)
      .then(data => {
        if (data.ok) {
          this.setState({ processingRequest: false, signUpComplete: SignUpState.COMPLETE });
        } else {
          throw new Error(data);
        }
      }).catch(error => {
        this.setState({ processingRequest: false, signUpComplete: SignUpState.ERROR })
      });
  }

  render() {
    const signUpComplete = this.state.signUpComplete;
    const validEmail = this.state.validEmail;
    return (
      <div className="container mt-28 -mb-20 flex items-center">
        <div className="w-full md:w-1/2 flex flex-col z-10">
          <h1 className="mb-6">{this.props.title} </h1>
          {signUpComplete === SignUpState.INCOMPLETE || signUpComplete === SignUpState.ERROR ? (
            <>
              <label htmlFor="emailAddress" className="mb-2">
                Email Address
              </label>
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                className={`input-field w-full md:w-7/12 mb-2 ${!validEmail ? 'input-error ' : ''}`}
                value={this.state.email}
                onBlur={this.handleChange}
                onChange={this.handleChange}
              />
              {!validEmail && <p className="px-2 mb-2 text-sm">* Valid email required</p>}
              <label htmlFor="reminderDay" className="mb-2">
                Digital Sabbath Day
              </label>
              <select
                id="reminderDay"
                name="reminderDay"
                className="input-field select w-full md:w-7/12 mb-4"
                onChange={this.handleChange}
                onBlur={this.handleChange}
              >
                <option value="0">Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
              </select>

              <p className="text-sm w-full md:w-2/3 mb-4">
                {this.props.disclaimer}
              </p>
              <div className="mb-4">
                <button
                  onClick={this.handleSignUp}
                  className="w-full md:w-40 btn-primary"
                  disabled={this.state.processingRequest ? 'disabled' : ''}
                >
                  Sign Up
                </button>
                {signUpComplete === SignUpState.ERROR && (
                  <p className="text-red-700 w-full md:w-2/3 mb-4">
                    There was a problem signing you up, please try again later.
                  </p>
                )}
              </div>

              <a
                className="flex"
                href="https://faithtech.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2">A project by</span>
                <img src={FaithTech} alt="FaithTech logo" className="w-24" />
              </a>
            </>
          ) : (
            <>
              <img
                src={SignUpDone}
                alt="Two people jumping next to a sign that says 'You\'re In'"
                className="w-full md:w-80 block"
              />
              <p className="w-full md:w-2/3 h-64 my-4">
                You have signed up for the Digital Sabbath! You will receive a confirmation
                email shortly and a reminder email before {DAYS[this.state.reminderDay]}!
              </p>
            </>
          )}
        </div>
        <div className="hidden md:block md:w-2/3 md:absolute md:right-0">
          <Image
            fileName="hero.png"
            alt="A cup of coffee and a Bible on a wooden railing by a sunny lake"
          />
        </div>
      </div>
    )
  }
}


