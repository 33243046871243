import React from "react"

// import Heart from "../../static/images/heart.svg"
import Heart from "./heart"

export default function Testimonial({ children, heart_hex }) {
  return (
    <div className="pr-4 md:pr-0 w-full text-center my-12">
      <div className="inline-block">
        <div className="relative w-full">
          <Heart heart_hex={heart_hex} />
        </div>
        <div className="p-4 bg-white shadow-md rounded-md mb-4">
          <span>{children}</span>
        </div>
      </div>
    </div>
  )
}
