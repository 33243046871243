import React, { useState } from "react"
import useBoop from "../../utils/use-boop"
import { animated } from "react-spring"

export default function Heart({
  //set default value as dark green
  heart_hex = "#19852F",
}) {
  const [style, trigger] = useBoop({ rotation: 25 })
  let stroke = shadeColor(heart_hex, -20)
  const [filled, setFilled] = useState(false)
  return (
    <animated.div
      className="absolute top-right cursor-pointer"
      style={style}
      onMouseEnter={trigger}
      //have to use an arrow function here or else we will end up with infinite re-renders
      //if we just used onClick{setFilled(!filled)}, JSX would be compiled to onClick: setFilled(!filled)
      //this immediate invoking of setFilled would result in a re-render since that effects the path's fill
      //this re-render would run through this process again... infinite re-renders!
      onClick={() => setFilled(!filled)}
    >
      <svg
        width="38"
        height="38"
        viewBox="-1 0 40 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-8"
        alt="Heart icon"
      >
        <circle
          cx="19"
          cy="19"
          r="18.5"
          fill={heart_hex}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M28.0653 12.0935C28.7838 12.6166 29.3696 13.3008 29.7758 14.0913C30.307 15.1361 30.4915 16.3231 30.3025 17.4799C30.1134 18.6374 29.5602 19.7045 28.7233 20.5261L28.7148 20.5345L28.7065 20.5431L19.1995 30.3324C19.1995 30.3324 19.1994 30.3325 19.1994 30.3325C19.1979 30.334 19.1962 30.3351 19.1943 30.3359C19.1924 30.3367 19.1903 30.3372 19.1882 30.3372C19.1861 30.3372 19.184 30.3367 19.1821 30.3359C19.1815 30.3357 19.1808 30.3353 19.1803 30.335C19.1791 30.3343 19.178 30.3334 19.177 30.3324C19.177 30.3324 19.1769 30.3324 19.1769 30.3324L9.65692 20.5249L9.64907 20.5169L9.64105 20.509C8.80784 19.6884 8.25781 18.6237 8.0708 17.4693C7.88379 16.315 8.06953 15.131 8.60106 14.0894L8.60108 14.0894L8.60372 14.0842C9.00217 13.2919 9.58393 12.6061 10.3007 12.0837C11.0174 11.5614 11.8484 11.2177 12.7247 11.0811L12.7247 11.0811L12.7376 11.079C13.6202 10.9295 14.5257 10.9928 15.3789 11.2637C16.2321 11.5346 17.0083 12.0053 17.643 12.6365L17.6587 12.652L17.6749 12.6668L18.515 13.4317L19.1897 14.046L19.8631 13.4302L20.7305 12.6369L20.742 12.6264L20.7531 12.6156C21.3904 11.9954 22.1642 11.5332 23.0124 11.2664C23.8607 10.9995 24.7597 10.9353 25.6373 11.079C26.5148 11.2226 27.3465 11.5701 28.0653 12.0935Z"
          fill={filled ? "white" : null}
          stroke={filled ? null : "white"}
          strokeWidth="2"
        />
      </svg>
    </animated.div>
  )
}

//programatically darken hex code to get stroke
function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16)
  var G = parseInt(color.substring(3, 5), 16)
  var B = parseInt(color.substring(5, 7), 16)

  R = parseInt((R * (100 + percent)) / 100)
  G = parseInt((G * (100 + percent)) / 100)
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16)

  return "#" + RR + GG + BB
}
